$dark-blue: #001854;
* {
  margin: 0;
  padding: 0;
}

.btn {
  border-radius: 4px;
  background-color: $dark-blue;
  font-weight: 700;
  border: none;
  color: #fff;
  padding: 10px 15px;
  text-align: center;
  display: block;
  line-height: 20px;
  outline: none;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  border-radius: 20px;
  max-width: 400px;
  margin: 0 auto;
}

body {
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  color: #000;
  line-height: 1.3;
  background-color: #f4f5f9;
}

.page {
  padding: 65px 0 70px;
}
.app-title {
  font-size: 32px;
  margin-bottom: 1rem;
}
.color-line {
  width: 100%;
  height: 32px;
  border: 0;
  display: block;
}

section {
  padding: 0 25px 0;
}

.how-it-works {
  &__cta-wrapper {
    width: 100%;
  }

  &__video-container {
    margin-top: 32px;
    margin-bottom: 100px;
    width: 100%;
    height: 100%;
  }

  &__video {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
